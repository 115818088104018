import React from 'react';
import './AboutPage.css';

const About = () => {
  return (
    <div className="about">
      I am a San Francisco based technologist. Prior, I finished my undergrad at Stanford, and was a grad student before starting work.
      <br /><br />In capitalist America, free is never really free, so you should do well to wonder if I'm ultimately building my personal brand through 'thought leadership', or aiming at some other flavor of 'peoplemaxxing'. On the other hand, 'grifters' is a response to the phenomenon of overmarketing and gaming the system. Truth is, personal branding is a domain of hegemony. Appealing to luck for the slim potential of a breakout article is overwrought. This website mainly exists to think out loud, and bring about contradictions within my own thinking.
      <br /><br />I dig time capsules, hearty soup, action RPG, working on the future. I am a distant descendent of the royle ranks of the Qing dynasty, hence my last name. My mother is an accountant and my father is a purchasing manager. I generally try to be very online.
      <br /><br />
      <br /><br />Don't hesitate, say hello — <span className="social">tau@tauj.in</span>
      <br /><br /><br /><br />Socials
      <br /><a className="social" href="mailto:tau@tauj.in">Email</a>, <a className="social" href="https://www.linkedin.com/in/tau-j-962218109/">LinkedIn</a>, <a className="social" href="https://x.com/zhengtaojin">Twitter</a>
      <br /><br />Fonts used
      <br /><a className="social" href="https://practicaltypography.com/charter.html">Charter</a>, <a className="social" href="https://www.colophon-foundry.org/typefaces/apercu">Apercu</a>
    </div>
  );
};

export default About;