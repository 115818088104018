import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import HeaderLogo from './HeaderLogo';

const Header = () => {
  return (
    <header className="header">
      <div className="header-left">
        <HeaderLogo />
      </div>
      <div className="header-center">
      </div>
      <div className="header-right">
        <h2>
          <Link to="/about">About
          <span className="complementary-color"> / </span>
          Contact
          </Link>
        </h2>
      </div>
    </header>
  );
};

export default Header;