import React from 'react';
import { Link } from 'react-router-dom';
import './HeaderLogo.css';

const HeaderLogo = () => {
  return (
    <>
        <div className="circle-text-container">
          <Link to="/">
            <h2 className="title">Tau Jin<span className="complementary-color">.</span></h2>
            <svg viewBox="0 0 100 100" className="circle-text">
              <path id="circlePath" d="M 50, 50 m -40, 0 a 40,40 0 1,1 80,0 a 40,40 0 1,1 -80,0"/>
              <text>
                <textPath href="#circlePath" startOffset="0%">
                  U<tspan className="plus-sign">+</tspan>91D1
                </textPath>
                <textPath href="#circlePath" startOffset="33%">
                  U<tspan className="plus-sign">+</tspan>6B63
                </textPath>
                <textPath href="#circlePath" startOffset="66%">
                  U<tspan className="plus-sign">+</tspan>97EC<tspan className="plus-sign">.</tspan>
                </textPath>
              </text>
            </svg>
          </Link>
        </div>
    </>
  );
};

export default HeaderLogo;